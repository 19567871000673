export const HOME_CLASSES = {
  stories: 'slideArtigos',
  events: 'slideInscricoes',
  marketing: 'bannerUniversity',
  corporate: 'bannerCorporate',
};

export function classNameCreatorForCategories(title: string): string {
  switch (title) {
    case 'cultura-diversidade-and-pessoas':
      return 'slideCultura';
    case 'novas-tecnologias-e-disrupcoes':
      return 'slideTec';
    case 'novos-modelos-de-gestao-ageis':
      return 'slideGetao';
    case 'estrategias-de-vendas-e-marketing':
      return 'slideVendasMarketing';
    case 'inovacao':
      return 'slideInovacao';
    case 'transformacao-digital':
      return 'slideTransformacaoDigital';
    case 'empreendedorismo':
      return 'slideEmpreendedorismo';
    case 'carreira-and-novas-habilidades-humanas':
      return 'slideNovasHabilidades';
    default:
      return classNameCreatorForRecomendations(title, '-');
  }
}

export function classNameCreatorForRecomendations(
  title: string,
  characterToSplit = ' ',
): string {
  const titleSplit = title.split(characterToSplit);
  const titleCamelCase = titleSplit
    .map(
      value =>
        `${value.charAt(0).toUpperCase()}${value
          .substr(1)
          .toLocaleLowerCase()}`,
    )
    .join('');
  return `slide${titleCamelCase}`
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}
