import { IRecommendationsList } from '@models/content.interface';
import { formatRecommendations } from '@utils/format';
import api from '../api';

export const getRecommendations = async (
  userId: string,
): Promise<IRecommendationsList[]> => {
  const { data } = await api.get(`recommendations?userId=${userId}`);
  const notEmptyRecommendations = data.filter(rec => rec.contents.length > 0);
  const formattedRecommendations = formatRecommendations(
    notEmptyRecommendations,
  );
  return formattedRecommendations;
};
