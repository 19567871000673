import CloseIcon from '@material-ui/icons/Close';

import { Dialog, CloseButton } from './styles';

export interface IRatingModal {
  open: boolean;
  onClose: () => void;
}

const RatingModal: React.FC<IRatingModal> = ({ open, onClose, children }) => {
  return (
    <Dialog open={open} onClose={onClose} hideBackdrop disableScrollLock>
      <CloseButton onClick={onClose}>
        <CloseIcon fontSize="default" />
      </CloseButton>
      {children}
    </Dialog>
  );
};

export default RatingModal;
