import { ICardStoriesProps } from '@components/core/StoriesList/Card';

import { IStory } from '@models/story.interface';
import { formatStories } from '@utils/format';
import api from '../api';

export const getStories = async (): Promise<ICardStoriesProps[]> => {
  const responseStories = await api.get('stories');
  const stories: IStory = responseStories.data;

  return formatStories(stories) || [];
};
