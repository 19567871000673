import {
  AdManager,
  Footer,
  SliderContent,
  StoriesList,
} from '@components/core';
import { allAds } from '@components/core/AdManager';
import EventCards from '@components/pages/eventos/EventCards';
import { useTracking } from '@hooks/tracking';
import { useUser } from '@hooks/user';
import { Box } from '@material-ui/core';
import { getContentForCategories } from '@services/content/categories';
import { getRecommendations } from '@services/content/recommendations';
import {
  classNameCreatorForCategories,
  classNameCreatorForRecomendations,
  HOME_CLASSES,
} from '@utils/classNameCreator';

import {
  ContainerSlider,
  EventMarginPage,
  TitleUnloggedMobile,
} from '@styles/pages/Home';
import { getRandomPains } from '@utils/pains';
import dynamic from 'next/dynamic';

import { AllHomeProps } from 'pages';
import { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import ModalHome from '../Modal';

const HomeSkeleton = dynamic(() => import('@components/pages/HomeSkeleton'));
const Stories = dynamic(() => import('@components/core/Stories'));

const MobileHome: React.FC<AllHomeProps> = ({
  stories,
  futureEvents,
  liveEvents,
  futureSpeakers,
  liveSpeakers,
}) => {
  const { track } = useTracking();
  const { user } = useUser();
  const perPage = 1;
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(true);
  const [elements, setElements] = useState([]);
  const [elementsInPage, setElementsInPage] = useState<JSX.Element[]>([]);

  const [openStories, setOpenStories] = useState<{
    index?: number;
    initial?: number;
    open?: boolean;
  }>({
    index: undefined,
    initial: undefined,
    open: false,
  });

  const handleOpenStories = useCallback(
    (
      event?: React.MouseEvent<HTMLElement, MouseEvent>,
      index?: number,
      initial?: number,
    ) => {
      event?.stopPropagation();
      setOpenStories({ index, initial, open: true });
    },
    [],
  );

  const handleCloseStories = useCallback(
    (event?: React.MouseEvent<HTMLElement, MouseEvent>) => {
      event?.stopPropagation();
      setOpenStories({ ...openStories, open: false });
    },
    [openStories],
  );

  const getContents = async () => {
    const win = window as any;
    let recommendations = [];
    const userId = user?.email || win.KM?.i();

    if (userId) {
      recommendations = await getRecommendations(userId);
    }
    const categories = await getContentForCategories(
      user.pains?.length
        ? user.pains.map(pain => pain)?.join(',')
        : getRandomPains()
            ?.map(pain => pain?.slug)
            ?.join(','),
    );

    const recComponents = recommendations?.map(rec => (
      <ContainerSlider key={rec.section}>
        <SliderContent
          WebSerie={false}
          contents={rec.contents}
          title={rec.section}
          onClick={() => {
            track('Clicked in recommendation section', {
              section: rec.section,
            });
          }}
          className={classNameCreatorForRecomendations(rec.section)}
        />
      </ContainerSlider>
    ));
    const catComponents = categories?.map(cat => {
      if (cat?.stories?.length > 0) {
        return (
          <ContainerSlider key={cat.slug}>
            <SliderContent
              WebSerie={false}
              contents={cat?.stories}
              title={cat?.name}
              onClick={() => {
                track('Clicked in category section', {
                  section: cat.name,
                });
              }}
              className={classNameCreatorForCategories(cat.slug)}
            />
          </ContainerSlider>
        );
      }
      return <></>;
    });

    const allItems = [
      ...recComponents,
      ...catComponents,
      <EventMarginPage className={HOME_CLASSES.events}>
        <EventCards
          liveEvents={liveEvents}
          futureEvents={futureEvents}
          liveSpeakers={liveSpeakers}
          futureSpeakers={futureSpeakers}
        />
      </EventMarginPage>,
      <Footer />,
    ];

    setElements(allItems);
    setElementsInPage(allItems.slice(0, 3));
    setLoading(false);
  };

  useEffect(() => {
    getContents();
    // eslint-disable-next-line
  }, [user?._id]);

  const handleNext = () => {
    setPage(page + 1);
    setElementsInPage(elements.slice(0, (page + 1) * perPage));
  };

  return (
    <>
      <ModalHome />
      <TitleUnloggedMobile>Você não pode perder</TitleUnloggedMobile>
      <StoriesList
        onClick={index => handleOpenStories(undefined, undefined, index)}
        contents={stories}
        className={HOME_CLASSES.stories}
      />
      <Box my="20px">
        <AdManager ad={allAds['platform-home-mobile']} />
      </Box>
      {!loading ? (
        <InfiniteScroll
          dataLength={elementsInPage.length}
          hasMore={elementsInPage.length < elements.length}
          next={handleNext}
          loader={<></>}
          scrollableTarget="scrollableDiv"
        >
          {elementsInPage}
        </InfiniteScroll>
      ) : (
        <HomeSkeleton />
      )}
      {openStories.open && (
        <Stories
          stories={stories}
          handleClose={handleCloseStories}
          open={openStories.open}
          initialSlide={openStories.initial}
        />
      )}
    </>
  );
};

export default MobileHome;
